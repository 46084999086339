import { environment } from '@agroone-app/env/environment'
import { NetworkService, Parameters } from '@agroone-front/shared'
import { MsSqlPaginatedData, Variety } from '@agroone/entities'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { map, Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class VarietyService {
  constructor(private http: HttpClient, private networkService: NetworkService) {}

  public getAll(): Observable<Variety[]> {
    const params = new Parameters()
    params.noPagination = true

    const options: {
      params: { [key: string]: string | number | boolean }
    } = {
      params: this.networkService.getHttpParams(params),
    }

    return this.http.get<MsSqlPaginatedData<Variety>>(`${environment.newApiUrl}${environment.varieties}`, options).pipe(
      map((result: MsSqlPaginatedData<Variety>) => {
        if (result?.data) {
          const varieties = result?.data?.map((variety) => new Variety(variety))
          // Sort alphabetically
          varieties.sort((x, y) => x.name.localeCompare(y.name))
          return varieties
        }
      })
    )
  }
}
