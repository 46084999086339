import {
  combineLatest,
  debounceTime,
  firstValueFrom,
  from,
  map,
  mergeMap,
  Observable,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs'

import { environment } from '@agroone-app/env/environment'
import {
  addCrop,
  CropHttpParams,
  loadCrops,
  loadCropsSuccess,
} from '@agroone-app/scene/crop-management/crop/store/actions/crop.actions'
import { CropState } from '@agroone-app/scene/crop-management/crop/store/reducers'
import { selectCrops } from '@agroone-app/scene/crop-management/crop/store/selectors/crop.selectors'
import { NetworkService, Parameters, SharedPermissionService, SharedUserService } from '@agroone-front/shared'
import { DateFormatV2, formatDateV2 } from '@agroone/dates'
import {
  Crop,
  CropActivity,
  Field,
  MsSqlPaginatedData,
  Offer,
  PesticideProductLite,
  Scouting,
  UserPermissions,
  VirtualCrop,
} from '@agroone/entities'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Actions, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'

@Injectable({
  providedIn: 'root',
})
export class CropService {
  constructor(
    private http: HttpClient,
    private cropStore: Store<CropState>,
    private actions$: Actions,
    private userService: SharedUserService,
    private networkService: NetworkService,
    private permissionService: SharedPermissionService
  ) {}
  private cropsUrlApi: string = `${environment.newApiUrl}${environment.crops}`
  private virtualCropsUrlApi: string = `${environment.newApiUrl}${environment.crops}/virtual`

  /**
   * Get crop by id
   * first from the  crop state, else from the remote server
   *
   * @param id
   * @returns
   */
  public get(id: number): Observable<Crop> {
    return this.cropStore.select(selectCrops).pipe(
      take(1),
      debounceTime(200),
      mergeMap((state) => {
        const savedCrop = state.find((si) => si.id === id)
        if (savedCrop) {
          return of(savedCrop)
        }

        // In case we never had this crop in the local state we fetch the specific crop
        return this.http.get<Crop>(`${this.cropsUrlApi}/${id}`).pipe(
          tap((crop) => {
            this.cropStore.dispatch(addCrop({ payload: { crop, options: { insert: false } } }))
          })
        )
      })
    )
  }

  /**
   * Get all crops
   *
   * @param options
   * @returns
   */
  public getAll(options: CropHttpParams): Observable<Crop[]> {
    const config: { [key: string]: any } = {}
    if (options.params) {
      config.params = this.networkService.getHttpParams(options.params)
    }
    if (options.includeOffers) {
      config.params.includeOffers = options.includeOffers
    }

    const query = options.fieldId ? `?fieldId=${encodeURIComponent(options.fieldId)}` : '/'

    return of(
      this.permissionService.isGranted([
        UserPermissions.CROPS_VIEW,
        UserPermissions.CROPS_EDIT,
        // UserPermissions.THIRD_PARTY_APPLICATOR_TASK_EDIT,
        UserPermissions.WORKLIST_EDIT,
        UserPermissions.GEOLOCATION_VIEW,
        UserPermissions.HARVEST_MODULE_ACCESS,
      ])
    ).pipe(
      switchMap((hasPermission) => {
        if (hasPermission) {
          return this.http.get<MsSqlPaginatedData<Crop>>(`${environment.newApiUrl}/crops${query}`, config).pipe(
            switchMap((response: MsSqlPaginatedData<Crop>) => {
              if (response.currentPage < response.pageCount) {
                return combineLatest(
                  Array.from({ length: response.pageCount - 1 }, (_, i) =>
                    this.http.get<MsSqlPaginatedData<Crop>>(
                      `${environment.newApiUrl}/crops${query}`,
                      this.getConfig(config, i + 1)
                    )
                  )
                ).pipe(
                  map((v) => {
                    v.unshift(response)
                    return v
                  })
                )
              }
              return of([response])
            }),
            map((v) => v.map((v) => v.data).flat())
          )
        } else {
          return of([])
        }
      })
    )
  }

  /**
   * Get virtual crop by id
   *
   * @param id
   * @returns
   */
  public getVirtual(id: number): Observable<Crop> {
    return this.http.get<Crop>(`${this.virtualCropsUrlApi}/${id}`)
  }

  /**
   * Get all crops
   *
   * @param options
   * @returns
   */
  public getAllVirtual(options: CropHttpParams): Observable<Crop[]> {
    const config: { [key: string]: any } = {}
    if (options.params) {
      config.params = this.networkService.getHttpParams(options.params)
    }

    return of(
      this.permissionService.isGranted([
        UserPermissions.CROPS_VIEW,
        UserPermissions.CROPS_EDIT,
        UserPermissions.GEOLOCATION_VIEW,
      ])
    ).pipe(
      switchMap((hasPermission) => {
        if (hasPermission) {
          return this.networkService.getAllFromPaginated<Crop>(`${this.virtualCropsUrlApi}`, config)
        }

        return of([])
      })
    )
  }

  /**
   * Return the crops by field ID
   * Check the store then the remote
   *
   * @param field
   * @returns
   */
  public getByField(field: Field): Observable<Crop[]> {
    return this.cropStore.select(selectCrops).pipe(
      take(1),
      mergeMap((state) => {
        state = state.filter((si) => si.fieldId === field.id)
        if (state.length) {
          this.fetchByField(field).subscribe()
          return of(state)
        } else {
          return this.fetchByField(field)
        }
      })
    )
  }

  /**
   * Add crop
   *
   * @param crop
   * @param options
   * @returns
   */
  public add(crop: Crop | VirtualCrop): Observable<Crop> {
    return this.http.post<Crop>(`${this.cropsUrlApi}`, crop)
  }

  /**
   * Update crop
   *
   * @param crop
   * @returns
   */
  public update(crop: Crop | VirtualCrop): Observable<Crop> {
    return this.http.put<Crop>(`${this.cropsUrlApi}`, crop)
  }

  /**
   *
   * @param item
   * @param patchValues
   * @returns
   */

  public patch(item: Crop | VirtualCrop, patchValues: { [key: string]: any }): Observable<Crop> {
    const valuesToPatch = {
      ...patchValues,
      id: item.id,
      region: item.isVirtual ? this.userService.currentUser.regionName : item.region,
      isVirtual: item.isVirtual,
    }
    return this.http.patch<Crop>(`${environment.newApiUrl}/crops`, valuesToPatch)
  }

  public delete(id: number): Observable<Crop> {
    const params: HttpParams = new HttpParams().set('date', formatDateV2(new Date(), DateFormatV2.DATETIME_OFFSET))
    return this.http.delete<Crop>(`${this.cropsUrlApi}/${id}`, { params })
  }

  public getActivitiesByCrop(cropId: number): Observable<CropActivity[]> {
    const params: Parameters = {
      filters: {},
    }

    const config: { [key: string]: any } = {}
    config.params = this.networkService.getHttpParams(params)

    return from(
      this.networkService.getAllFromPaginated<CropActivity>(`${environment.newApiUrl}/activities/${cropId}`, config)
    ).pipe(map((activities) => activities?.map((activity) => new CropActivity(activity)) ?? []))
  }

  public async getByGeohash(geohashes: string[]): Promise<Crop[]> {
    const result: Crop[] = (
      await Promise.all(
        geohashes.map((hash) => {
          const filter =
            `?filter=region="${this.userService.currentUser.regionName}",` +
            'endDate NOTEXISTS,' +
            `geohash STARTSWITH "${hash}"`
          return firstValueFrom(this.http.get<Crop[]>(`${environment.newApiUrl}/crops${filter}`))
        })
      )
    ).reduce((array: Crop[], cs: Crop[]) => {
      array = array.concat(cs)
      return array
    }, [])
    return result
  }

  public getCropBoundaries(crops: Crop[]): Observable<Crop[]> {
    if (crops.length) {
      return this.http
        .get<Crop[]>(
          `${environment.newApiUrl}${environment.geolocation}/crop/boundaries?ids=${crops.map((i) => i.id).join(',')}`
        )
        .pipe(
          map((res) => {
            if (res) {
              res = res.filter(Boolean).map((crop) => {
                const c = new Crop(crop as Crop)
                c.cropType = crops.find((x) => x.id === crop.id)?.cropType
                return c
              })
              return res
            }
          })
        )
    }
    return of([])
  }

  public getAvailablePesticides(cropId: number): Observable<PesticideProductLite[]> {
    return this.http.get<PesticideProductLite[]>(`${environment.newApiUrl}/crops/${cropId}/available-pesticides`)
  }

  public downloadPdf(cropId: number, activity: string, filename?: string): Observable<string> {
    const params: HttpParams = filename ? new HttpParams().set('filename', filename) : undefined
    return this.http.get<string>(`${environment.newApiUrl}/crops/pdf/${cropId}/${activity}`, {
      params,
      responseType: 'text' as 'json',
    })
  }

  /**
   * Performs the network call and returns the crops for
   * a given field ID
   */
  private fetchByField(field: Field): Observable<Crop[]> {
    const params: Parameters = {
      filters: {
        region: {
          fieldName: 'region',
          operator: 'equality',
          value: field.region,
        },
        endDate: {
          fieldName: 'endDate',
          operator: 'notexists',
        },
      },
    }
    return from(this.getAll({ params, fieldId: field.id })).pipe(
      map((state) =>
        state.filter((si) => {
          if (si.fieldId !== field.id) {
            return false
          }
          if (si.region !== field.region) {
            return false
          }
          return true
        })
      )
    )
  }

  public getByGrower(growerId: number): Observable<Crop[]> {
    const params: Parameters = {
      filters: {
        growerId: {
          fieldName: 'growerId',
          operator: 'equality',
          value: growerId,
        },
        endDate: {
          fieldName: 'endDate',
          operator: 'notexists',
        },
      },
    }
    this.cropStore.dispatch(loadCrops({ params }))
    return this.actions$.pipe(
      ofType(loadCropsSuccess),
      map((v) => v.crops)
    )
  }

  private getConfig(config: { [key: string]: any }, currentPage: number): { [key: string]: any } {
    if (config.params) {
      config.params.page = currentPage + 1
    } else {
      config = {
        params: { page: currentPage + 1 },
      }
    }
    return config
  }

  public dispatchLoadCrops(): void {
    const filters = {
      region: {
        fieldName: 'region',
        operator: 'equality',
        value: this.userService.currentUser.regionName,
      },
    }
    const params = {
      pageLength: 500,
      filters,
    } as Parameters
    this.cropStore.dispatch(loadCrops({ params }))
  }

  getDefaultApplicatorNameForCrop(cropId: number): Observable<string> {
    return this.http
      .get<{ name: string }>(`${this.cropsUrlApi}/${cropId}/default-applicator-name`)
      .pipe(map((v) => v.name))
  }

  getOffersForACrop(cropId: number): Observable<Offer[]> {
    return from(this.networkService.getAllFromPaginated<Offer>(`${this.cropsUrlApi}/${cropId}/offers`))
  }

  getScoutingForACrop(cropId: number): Observable<Scouting> {
    return this.http.get<Scouting>(`${this.cropsUrlApi}/${cropId}/scouting`)
  }
}
