import { SignatureComponent } from '@agroone-app/shared/dialog/components/signature.component'
import { ScrollingModule as ExperimentalScrollingModule } from '@angular/cdk-experimental/scrolling'
import { ScrollingModule, ViewportRuler } from '@angular/cdk/scrolling'
import { CommonModule } from '@angular/common'
import { ModuleWithProviders, NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatIconModule } from '@angular/material/icon'
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox'
import {
  MAT_LEGACY_DIALOG_DEFAULT_OPTIONS as MAT_DIALOG_DEFAULT_OPTIONS,
  MatLegacyDialogModule as MatDialogModule,
} from '@angular/material/legacy-dialog'
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field'
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input'
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner'
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select'
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle'
import { TranslateModule } from '@ngx-translate/core'

import { RequiredFieldModule } from '@agroone-app/shared/required-field/required-field.module'
import { ConstantModule } from '../constant/constant.module'
import { FormModule } from '../form/form.module'
import { MapModule } from '../map/map.module'
import { ConfirmComponent } from './components/confirm.component'
import { CreateNotificationFormComponent } from './components/create-notification-form.component'
import { CropCloseComponent } from './components/crop-close.component'
import { CropDataEditComponent } from './components/crop-data-edit.component'
import { CropFiltersComponent } from './components/crop-filters.component'
import { CsvUploadComponent } from './components/csv-upload.component'
import { FieldFiltersFormComponent } from './components/fields-filter-form.component'
import { FileModeComponent } from './components/file-mode.component'
import { GrowerCsrFileComponent } from './components/grower-csr-file.component'
import { InheritFieldComponent } from './components/inherit-field.component'
import { LocationModeComponent } from './components/location-mode.component'
import { NoteComponent } from './components/note.component'
import { OfferComponent } from './components/offer.component'
import { PictureGalleryComponent } from './components/picture-gallery.component'
import { PictureComponent } from './components/picture.component'
import { PlanningDialogComponent } from './components/planning.component'
import { ReportErrorsComponent } from './components/report-errors.component'
import { TechnicianComponent } from './components/technician.component'
import { WorklistFiltersComponent } from './components/worklist-filters.component'
import { FilterNumberPipe } from './pipes/filter-number.pipe'
import { DialogService } from './services/dialog.service'

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormModule,
    ConstantModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatInputModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    MapModule,
    ScrollingModule,
    ExperimentalScrollingModule,
    RequiredFieldModule,
  ],
  declarations: [
    CsvUploadComponent,
    NoteComponent,
    PictureComponent,
    PictureGalleryComponent,
    ConfirmComponent,
    SignatureComponent,
    LocationModeComponent,
    FileModeComponent,
    PlanningDialogComponent,
    CropFiltersComponent,
    FieldFiltersFormComponent,
    CropCloseComponent,
    FilterNumberPipe,
    TechnicianComponent,
    WorklistFiltersComponent,
    InheritFieldComponent,
    ReportErrorsComponent,
    OfferComponent,
    CropDataEditComponent,
    CreateNotificationFormComponent,
    GrowerCsrFileComponent,
  ],
  providers: [DialogService, ViewportRuler],
  exports: [
    CsvUploadComponent,
    NoteComponent,
    PictureComponent,
    PictureGalleryComponent,
    ConfirmComponent,
    LocationModeComponent,
    FileModeComponent,
    PlanningDialogComponent,
    CropFiltersComponent,
    FieldFiltersFormComponent,
    FilterNumberPipe,
    CropCloseComponent,
    TechnicianComponent,
    WorklistFiltersComponent,
    InheritFieldComponent,
    OfferComponent,
    CropDataEditComponent,
    CreateNotificationFormComponent,
    GrowerCsrFileComponent,
  ],
})
export class DialogModule {
  static forRoot(): ModuleWithProviders<DialogModule> {
    return {
      ngModule: DialogModule,
      providers: [
        {
          provide: MAT_DIALOG_DEFAULT_OPTIONS,
          useValue: {
            hasBackdrop: true,
          },
        },
      ],
    }
  }
}
