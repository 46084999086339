import { Injectable } from '@angular/core'
import { environment } from '@agroone-app/env/environment'
import { HttpClient } from '@angular/common/http'
import { from, Observable } from 'rxjs'
import { SaveSpecification, Specification } from '@agroone/entities'
import { NetworkService } from '@agroone-front/shared'

@Injectable({
  providedIn: 'root',
})
export class SpecificationsService {
  constructor(private http: HttpClient, private networkService: NetworkService) {}

  getAllSpecByRegion(): Observable<Specification[]> {
    return from(this.networkService.getAllFromPaginated<Specification>(`${environment.newApiUrl}/specifications`))
  }

  add(specification: SaveSpecification): Observable<Specification> {
    return this.http.post<Specification>(`${environment.newApiUrl}/specifications`, specification)
  }

  update(specification: SaveSpecification): Observable<Specification> {
    return this.http.put<Specification>(`${environment.newApiUrl}/specifications`, specification)
  }

  delete(specificationId: number) {
    return this.http.delete(`${environment.newApiUrl}/specifications/${specificationId}`)
  }
}
