import { Injectable } from '@angular/core'
import { environment } from '@agroone-app/env/environment'
import { HttpClient } from '@angular/common/http'
import { map, Observable, switchMap, tap } from 'rxjs'
import { MsSqlPaginatedData, NotificationDto, RecipientNotificationDto, SaveNotificationDto } from '@agroone/entities'

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private _notifications: NotificationDto[]

  constructor(private http: HttpClient) {}

  getMyNotifications$(): Observable<NotificationDto[]> {
    return this.http.get<MsSqlPaginatedData<NotificationDto>>(`${environment.newApiUrl}/notifications/mines`).pipe(
      map((v) => v.data),
      tap((v) => (this._notifications = v))
    )
  }

  getMyNotifications(): NotificationDto[] {
    return this._notifications
  }

  getNotificationRecipients(): Observable<RecipientNotificationDto[]> {
    return this.http
      .get<MsSqlPaginatedData<RecipientNotificationDto>>(`${environment.newApiUrl}/notifications/recipients`)
      .pipe(map((v) => v.data))
  }

  add(notification: SaveNotificationDto): Observable<SaveNotificationDto> {
    return this.http.post<SaveNotificationDto>(`${environment.newApiUrl}/notifications`, notification)
  }

  readAll() {
    return this.http
      .post<null>(`${environment.newApiUrl}/notifications/read-all`, null)
      .pipe(switchMap((v) => this.getMyNotifications$().pipe(map(() => v))))
  }

  markOneAsRead(notificationId: number) {
    return this.http
      .patch<NotificationDto>(`${environment.newApiUrl}/notifications/${notificationId}/mark-as-read`, null)
      .pipe(switchMap((v) => this.getMyNotifications$().pipe(map(() => v))))
  }
}
