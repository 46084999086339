import { AgrooneAppEnvironment } from '@agroone/entities'
import packageInfo from '../../../../package.json'

export const environment: AgrooneAppEnvironment = {
  production: true,
  envName: 'qlf',
  dynatraceScriptSrc: '',
  logLevel: 'DEBUG',
  serverLogLevel: 'DEBUG',
  version: packageInfo.version,
  newApiUrl: 'https://apiv2.cldqlf.bonduelle.com',
  cognitoDomain: 'https://ssoconnect.cldqlf.bnd-services.com',
  cognitoClientId: '4ui640sj1gqqf3fs64ft5mge6d',
  harvestUrl: 'https://agropilot.cldqlf.bonduelle.com/',
  masterdataUrl: 'https://masterdata.cldqlf.bonduelle.com/',
  crops: '/crops',
  virtualCrops: '/virtual-crops',
  fields: '/fields',
  scoutings: '/scoutings',
  regions: '/regions',
  users: '/users',
  varieties: '/varieties',
  recommendations: '/recommendations',
  geolocation: '/geolocation',
  interventions: '/interventions',
  worklists: '/worklists',
  tpatasks: '/tpa-tasks',
  croptypeForecasts: '/croptype-forecasts',
  hypothesis: '/hypothesis',
  sowingPlannings: '/sowing-plannings',
  growerProductionAreas: '/grower-production-areas',
  countries: '/countries',
  csr: '/csr',
  csrConstants: '/csr-constants',
  constants: '/constants',
  croptypes: '/croptypes',
  local: false,
  headerColorStart: '#ffa726',
  headerColorEnd: '#ffcc80',
  remoteMonitoring: true,
  remoteMonitoringRatio: 1,
}
