import { Injectable } from '@angular/core'
import {
  loadSpecifications,
  loadSpecificationsFailure,
  loadSpecificationsSuccess,
} from '../actions/specification.actions'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, distinctUntilChanged, map, mergeMap, of, tap } from 'rxjs'
import { SpecificationsService } from '@agroone-app/shared/specifications/services/specifications.service'
import { Store } from '@ngrx/store'
import { LoadingState, startLoadingSpecifications, stopLoadingSpecifications } from '@agroone-app/state'

@Injectable()
export class SpecificationEffects {
  constructor(
    private actions$: Actions,
    private specificationService: SpecificationsService,
    private loadingStore: Store<LoadingState>
  ) {}

  public getSpecifications$ = createEffect(() => {
    this.loadingStore.dispatch(startLoadingSpecifications())

    return this.actions$.pipe(
      ofType(loadSpecifications),
      distinctUntilChanged(),
      mergeMap(() =>
        this.specificationService.getAllSpecByRegion().pipe(
          tap(() => this.loadingStore.dispatch(stopLoadingSpecifications())),
          map((specifications) => loadSpecificationsSuccess({ specifications })),
          catchError((e) => of(loadSpecificationsFailure({ error: `${e}` })))
        )
      )
    )
  })
}
