import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { NutrientsComponent } from './nutrients.component'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { MatIconModule } from '@angular/material/icon'
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field'
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select'
import { TranslateModule } from '@ngx-translate/core'
import { NotInPipe } from './pipes/not-in.pipe'
import { FormModule } from '../form/form.module'
import { NutrientsActivityTypePipe } from './pipes/nutrients-activity-type.pipe'
import { NutrientsTypePipe } from './pipes/nutrients-type.pipe'
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner'

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    FormModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatProgressSpinnerModule,
  ],
  declarations: [NutrientsComponent, NotInPipe, NutrientsActivityTypePipe, NutrientsTypePipe],
  exports: [NutrientsComponent, NutrientsActivityTypePipe, NutrientsTypePipe],
})
export class NutrientsModule {}
