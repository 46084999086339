import { Injectable } from '@angular/core'
import { environment } from '@agroone-app/env/environment'
import { catchError, Observable, of, tap } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { LoggerService, SharedUserService } from '@agroone-front/shared'
import { Language } from '@agroone/entities'

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  public languages: Language[] = []
  public currentLanguageId: number

  constructor(private http: HttpClient, private logger: LoggerService, private userService: SharedUserService) {}

  init(): Observable<Language[]> {
    return this.http.get<Language[]>(`${environment.newApiUrl}/languages`).pipe(
      catchError((error) => {
        this.logger.error('Languages loading error', JSON.stringify(error))
        return of(this.languages)
      }),
      tap((res: Language[]) => {
        this.languages = res
        this.currentLanguageId = res?.find((l) => l?.name === this.userService.currentUser?.language)?.id
        this.logger.log('Languages initialized')
      })
    )
  }
}
