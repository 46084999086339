import { environment } from '@agroone-app/env/environment'
import {
  LoggerService,
  MonitoringService,
  SharedUserService,
  UserDefaultDateFormat,
  UserServiceModel,
} from '@agroone-front/shared'
import { DateFormats } from '@agroone/dates'
import { Profil, UserDto } from '@agroone/entities'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { DateAdapter } from '@angular/material/core'
import { TranslateService } from '@ngx-translate/core'
import { Observable } from 'rxjs'
@Injectable({
  providedIn: 'root',
})
export class UserService extends SharedUserService implements UserServiceModel {
  private readonly _allowedProfilesToCreateNotifications: Profil[] = [Profil.MANAGER, Profil.TECHNICIAN, Profil.SCOUT]
  private readonly _defaultDateFormat: DateFormats = DateFormats.DD_MM_YY
  public override readonly userApiUrl: string = `${environment.newApiUrl}${environment.users}`

  constructor(
    protected override readonly http: HttpClient,
    protected override readonly logger: LoggerService,
    private readonly translate: TranslateService,
    private readonly dateAdapter: DateAdapter<Date>,
    private readonly monitoringService: MonitoringService
  ) {
    super(http, logger)
  }

  public override init(): Observable<UserDto> {
    this.user.subscribe((user) => {
      if (user) {
        if (!user.defaultDateFormat) {
          user.defaultDateFormat = this._defaultDateFormat
        }
        this.currentUserDateFormat = new UserDefaultDateFormat(user.defaultDateFormat)
        this.currentUserDateHourFormat = new UserDefaultDateFormat((user.defaultDateFormat + ' HH:mm') as any)

        this._setLanguage(user.language)

        this.monitoringService.identifyUser(user.email)
        this.userCanUseNotificationFeature = this._allowedProfilesToCreateNotifications.includes(
          this.currentUser.profil
        )
      }
    })
    return this.getCurrentUser()
  }

  private _setLanguage(language: string) {
    this.translate.setDefaultLang(language)
    this.translate.use(language)
    this.dateAdapter.setLocale(language)
  }
}
