<div class="d-container full-width">
  <h3 class="bold">{{ 'GROWER_CSR_FILE.TITLE' | translate }}</h3>
  <form [formGroup]="csrFileFormGroup">
    <div class="d-content disp-f j-c-c a-c-c">
      <div formArrayName="csrFilesArray">
        <div
          *ngFor="let document of csrFilesArray.controls; let i = index"
          [formGroupName]="i"
          class="d-content-form disp-f j-c-c a-i-c mb-l"
        >
          <!-- Document Type -->
          <div class="d-content-form-select">
            <mat-label class="text-s mb-xs">{{ 'GROWER_CSR_FILE.SELECT_TYPE_LABEL' | translate }}</mat-label>
            <mat-select formControlName="type">
              <mat-option *ngFor="let type of documentTypes" [value]="type.value">
                {{ type.label | titlecase }}
              </mat-option>
            </mat-select>
          </div>
          <!-- File Upload -->
          <div class="d-content-form-file-upload">
            <mat-label class="text-s mb-xs">{{ 'GROWER_CSR_FILE.FILE_UPLOAD_LABEL' | translate }}</mat-label>
            <input
              #fileUpload
              matInput
              id="file-upload-{{ i }}"
              type="file"
              accept=".pdf"
              hidden
              (change)="onFileSelected($event, i)"
            />
            <button mat-stroked-button (click)="triggerSelectFiles(i)">
              <ng-conatiner *ngIf="csrFilesArray?.controls[i].get('file')?.value; else placeholder">
                <mat-icon svgIcon="added_pdf" />
                {{ csrFilesArray.controls[i].get('file')?.value?.name }}
              </ng-conatiner>
              <ng-template #placeholder>
                <mat-icon svgIcon="save_alt" />
                {{ 'GROWER_CSR_FILE.FILE_UPLOAD_PLACEHOLDER' | translate }}
              </ng-template>
            </button>
            <mat-error *ngIf="csrFilesArray?.controls[i].get('file').hasError('invalidFileType')" class="text-s mt-xxs">
              {{ 'GROWER_CSR_FILE.FILE_UPLOAD_ERROR' | translate }}
            </mat-error>
          </div>

          <!-- Remove Document -->
          <div class="d-content-form-remove-cross mt-xl">
            <mat-icon (click)="removeFilesArray(i)" svgIcon="delete-cross" />
          </div>
        </div>
      </div>

      <!-- Add New Document -->
      <div class="d-content-add-file mb-l">
        <button mat-button (click)="addCsrFileFormArray()" type="button">
          + {{ 'GROWER_CSR_FILE.ADD_NEW_FILE' | translate }}
        </button>
      </div>
      <!-- Actions -->
      <mat-dialog-actions class="d-content-actions disp-f j-c-c">
        <button
          [disabled]="csrFilesArray?.length === 0 || csrFileFormGroup?.invalid"
          [ngClass]="{ 'd-content-actions-disabled': csrFilesArray?.length === 0 || csrFileFormGroup.invalid }"
          class="mr-s"
          color="accent"
          mat-flat-button
          (click)="save()"
        >
          {{ 'DIALOG.BUTTON.OK' | translate }}
        </button>
        <button color="accent" mat-flat-button mat-dialog-close (click)="cancel()">
          {{ 'DIALOG.BUTTON.CANCEL' | translate }}
        </button>
      </mat-dialog-actions>
    </div>
  </form>
</div>
