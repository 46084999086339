// /* eslint-disable space-before-function-paren */
import { ActionReducer, MetaReducer, ReducerManager } from '@ngrx/store'
import { environment } from '@agroone-app/env/environment'
import {
  cropOns,
  initialCropState,
  initialVarietyState,
  scoutingOns,
  varietyOns,
} from '@agroone-app/scene/crop-management/crop/store/reducers'
import { Features } from './features.enum'
import { IdbStorageService } from './idb-storage.service'
import { recommendationOns } from '@agroone-app/scene/crop-management/crop/store/reducers/recommendation.reducer'
import { interventionOns } from '@agroone-app/scene/crop-management/crop/store/reducers/intervention.reducer'
import { fieldOns, initialFieldState } from '@agroone-app/scene/crop-management/field/store/reducers/field.reducer'
import { activityOns } from '@agroone-app/scene/crop-management/crop/store/reducers/activity.reducer'
import {
  initialCropDetailState,
  cropDetailOns,
} from '@agroone-app/scene/crop-management/crop/store/reducers/crop-detail.reducer'
import { growerOns, initialGrowerState } from '@agroone-app/scene/crop-management/grower/store/reducers'
import { initialOfflineState, offlineOns } from '@agroone-app/scene/crop-management/crop/store/reducers/offline.reducer'
import {
  initialSpecificationState,
  specificationOns,
} from '@agroone-app/scene/crop-management/crop/store/reducers/specification.reducer'

export function idbReducer(reducerManager: ReducerManager, storage: IdbStorageService) {
  return async () => {
    const [
      growerIdbRehydrateReducer,
      fieldIdbRehydrateReducer,
      cropIdbRehydrateReducer,
      cropDetailIdbRehydrateReducer,
      offlineIdbRehydrateReducer,
      varietyStateIdbRehydrateReducer,
      specificationStateIdbRehydrateReducer,
    ] = await Promise.all([
      await storage.createIdbRehydrateReducer(Features.GROWER, initialGrowerState, ...growerOns),
      await storage.createIdbRehydrateReducer(Features.FIELD, initialFieldState, ...fieldOns),
      await storage.createIdbRehydrateReducer(Features.CROP, initialCropState, ...cropOns),
      await storage.createIdbRehydrateReducer(
        Features.CROP_DETAIL,
        initialCropDetailState,
        ...cropDetailOns,
        ...scoutingOns,
        ...recommendationOns,
        ...interventionOns,
        ...activityOns
      ),
      await storage.createIdbRehydrateReducer(Features.OFFLINE, initialOfflineState, ...offlineOns),
      await storage.createIdbRehydrateReducer(Features.VARIETY, initialVarietyState, ...varietyOns),
      await storage.createIdbRehydrateReducer(Features.SPECIFICATION, initialSpecificationState, ...specificationOns),
    ])

    reducerManager.addReducers({
      grower: growerIdbRehydrateReducer,
      field: fieldIdbRehydrateReducer,
      crop: cropIdbRehydrateReducer,
      cropDetail: cropDetailIdbRehydrateReducer,
      offline: offlineIdbRehydrateReducer,
      variety: varietyStateIdbRehydrateReducer,
      specification: specificationStateIdbRehydrateReducer,
    })
  }
}

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    const nextState = reducer(state, action)

    if (!environment.production) {
      console.group(action.type)
      console.log(`%c prev state`, `color: #9E9E9E; font-weight: bold`, state)
      console.log(`%c action`, `color: #03A9F4; font-weight: bold`, action)
      console.log(`%c next state`, `color: #4CAF50; font-weight: bold`, nextState)
      console.groupEnd()
    }
    return nextState
  }
}

export const metaReducers: MetaReducer<any>[] = [debug]
